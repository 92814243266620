body {
  margin: 0;
  font-family: "Fira Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #007db3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
  width: 100%;
  height: 350px;
  border-radius: 5px;
}

.leaflet-control-attribution {
  font-size: 5px;
}